<template>
  <div class="row-vertical-center">
    <Mail v-if="icon == 'mail'" class="icon"/>
    <Phone v-if="icon == 'phone'" class="icon"/>
  </div>
</template>

<script>
import Mail from '../assets/icons/mail_fill.svg'
import Phone from '../assets/icons/phone_fill.svg'

export default {
  props: {
    icon: ''
  },
  components: {
    Mail,
    Phone,
  }
}
</script>

<style>
.icon {
  padding-right: 10px;
}
</style>