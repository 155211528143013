import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './style/main.css'
import i18n from './i18n'

Vue.config.productionTip = false

import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
Vue.component('v-calendar', Calendar)
Vue.component('v-date-picker', DatePicker)

import { vfmPlugin } from 'vue-final-modal'
Vue.use(vfmPlugin)

// import VueYoutube from 'vue-youtube'
// Vue.use(VueYoutube)
import VueYouTubeEmbed from 'vue-youtube-embed'
Vue.use(VueYouTubeEmbed)

import Meta from 'vue-meta'
Vue.use(Meta)

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
