<template>
  <div>
    <modal-header v-if="!mobile" :title="$t('gift-modal.modal-title')" />
    <h1 v-else class="text--primary">Give a gift</h1>
    <div :class="{'gift-content': !mobile, 'gift-content-mobile': mobile}">
      <div class="gift-information">
        <h4 class="text--primary">{{ $t('gift-modal.left-header') }}</h4>
        <p style="margin: 20px 0">{{ $t('gift-modal.left-info1') }}</p>
        <ul>
          <li v-for="(item, index) in listItems" :key="index">
            <p style="margin: 5px 0">{{ item }}</p>
          </li>
        </ul>
        <p class="text-bold">{{ $t('gift-modal.thank-you') }}</p>
      </div>
      <div class="gift-payment"> 
        <h4 class="text--primary">{{ $t('gift-modal.pay-header') }}</h4>
        <p>
          Vintergatan 21, 256 65 Helsingborg<br>
          0731-40 77 66 · <a href="mailto:info@focusontheharvest.net" class="text-hoverable text--primary">info@focusontheharvest.net</a><br>
          Org. nr 802465-9859<br>
        </p>
        <p v-if="$i18n.locale == 'sv'">
          <span class="text-bold">Swedbank</span> Bg-nr 869-3939<br>
          <span class="text-bold">Banknummer</span> 8214-9, 923 484 585-8
        </p>
        <p v-else>
          <span class="text-bold">Bank, Swedbank, Sweden</span><br>
          <span class="text-bold">IBAN: </span>SE 69 8000 0821 4992 3484 5858<br>
          <span class="text-bold">BIC: </span> SWEDSESS<br>
          <span class="text-bold">Bank account: </span>8214 9923 4845 858
        </p>
        <p>
          <span class="text-bold">Swish</span> 123 352 84 52
        </p>
        <QRCodeSvg />
      </div>
    </div>
  </div>
</template>

<script>
import ModalHeader from './ModalHeader.vue'
import QRCodeSvg from '../../assets/swish_qr.svg'

export default {
  components: {
    ModalHeader,
    QRCodeSvg
  },
  props: {
    mobile: false,
  },
  computed: {
    listItems() {
      const raw = this.$t('gift-modal.list-items')

      return raw.split('|')
    }
  },
}
</script>

<style>

.gift-content {
  width: 100%;
  display: flex;
  max-height: 90vh;
  overflow-y: auto;
}

.gift-content-mobile {
  width: 100%;
}

.gift-information {
  flex: 4;
  padding: 40px;
  text-align: left;
}

.gift-information > ul {
  margin: initial;
  padding: 0 30px;
}

.gift-information > ul > li {
  color: black;
}

.gift-payment {
  flex: 3;
  padding: 40px;
  text-align: left;
}

@media screen and (max-width: 900px) {
  .gift-content {
    flex-direction: column;
  }
}
</style>