<template>
  <router-link :to="`news/${data.slug}`">
    <div class="home-news-backdrop noselect card-hoverable">
      <article style="padding: 30px; text-align: left; display: flex">
        <img v-if="data.images[0]" :src="data.images[0]" class="noselect home-news-image">
        <div>
          <h3 class="text--primary text-hoverable" style="margin: 0; font-size: 24px">{{ data.title[+($i18n.locale !== 'sv')] }}</h3>
          <p class="text-light" style="text-transform: capitalize; margin: 0">{{ date }}</p>
          <p class="news-list-item-content cap4" style="white-space: pre-wrap">{{ plain }}</p>
        </div>
      </article>
      <div class="home-news-footer">
        <p class="text-hoverable text--white" style="margin: 0">{{ $t('news.read-more') }}</p>
      </div>
    </div>
  </router-link>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    data: {},
  },
  computed: {
    date() {
      moment.locale(this.$i18n.locale)
      return moment(this.data.time).utc().format('MMM DD, YYYY')
    },
    plain() {
      let html = this.data.content[+(this.$i18n.locale !== 'sv')].replaceAll('<br>',' ')
      // let html = this.data.content[+(this.$i18n.locale !== 'sv')].replaceAll('<br>',' ').replaceAll(/(<\/.{1,2}>{1})/g, '\n$1')
      let div = document.createElement("div");
      div.innerHTML = html;
      const plaintext = div.innerText;

      return plaintext
    }
  }
}
</script>

<style>
.home-news-backdrop {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  border-radius: 10px;
  min-height: 280px;
}

.home-news-footer {
  background-color: #e5b409;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 30px;
  border-radius: 0 0 10px 10px;
}

.home-news-image {
  min-width: none;
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-right: 30px;
  border-radius: 10px;
}

.cap4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media screen and (max-width: 700px) {
  .home-news-image {
    min-width: none;
    width: 70px;
    height: 70px;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 5px;
  }
}
</style>