import Vue from 'vue'
import Vuex from 'vuex'

import { auth, storage, db, functions, analytics } from '../firebase/config'

import router from '../router'

import i18n from '../i18n'

import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth'

import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject
} from 'firebase/storage'
import { addDoc, collection, deleteField, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'

import { logEvent } from 'firebase/analytics'

import { load } from 'recaptcha-v3'

import moment from 'moment'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    user: null,
    isAuthReady: false,
    showModal: false,
    newsletterFooterEmail: '',
    activeModal: 0,
    news: {},
    events: [],
    videoCollections: [],
    filterYear: 0,
    filterOrder: 0,
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload
      if(state.user) i18n.locale = 'sv'
      console.log('User state changed: ', state.user)
    },
    setIsAuthReady(state, payload) {
      state.isAuthReady = payload
      console.log('Auth is now ready')
    },
    setNews(state, payload) {
      state.news = payload
    },
    setEvents(state, payload) {
      state.events = payload
    },
    setVideoCollections(state, payload) {
      state.videoCollections = payload
    },
    setFilterYear(state, payload) {
      state.filterYear = payload
    },
    setFilterOrder(state, payload) {
      state.filterOrder = payload
    },
    showModal(state, payload) {
      // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      //   //If mobile, navigate to a new page
      //   console.log('This is a mobile browser')
      //   router.push('/modal')
      // } else {
        //Else, show a modal
        // console.log('This is NOT a mobile browser')
        // this.$route.push('/')
        // console.log(router)

        state.showModal = true
        state.activeModal = payload
      // }
      
    },
    hideModal(state, payload) {

    }
  },
  actions: {
    async signIn(context, { email, password }) {
      console.log('Signing in: ', email)

      const respons = await signInWithEmailAndPassword(auth, email, password)
      if (respons) {
        context.commit('setUser', respons.user)
      } else {
        throw Error('Could not sign in user: ', email)
      }
    },
    async signOut(context) {
      await signOut(auth)
      context.commit('setUser', null)
    },
    async uploadFile(context, { folder, file, time }) {
      const fileRef = ref(storage, `${folder}/${time.toString().replace('.', '+')}-${file.name}`)
      const snapshot = await uploadBytes(fileRef, file)
      console.log('File succesfully uploaded!', snapshot)
      return await getDownloadURL(fileRef)
    },
    async removeFile(context, { url }) {
      const fileRef = ref(storage, url)
      try {
        await deleteObject(fileRef)
        console.log('Successfully removed file!')
      } catch (e) {
        console.log(e.message)
      }
    },
    async publishNews(context, { title, content, images, slug, timeStamp }) {
      const data = {
        "title": title,
        "content": content,
        "images": images,
        "time": timeStamp,
        "slug": slug,
      }

      const ref = doc(db, "public", "news")

      console.log(timeStamp)

      await updateDoc(ref, {
        [timeStamp]: data,
      })

      //Refresh loaded news to include this one
      context.dispatch('fetchNews')

      console.log('News successfully published!')
    },
    async deleteNews(context, { data }) {
      console.log('DELETING NEWS ARTICLE: ', data)
      
      const ref = doc(db, "public", "news")

      //Delete images
      for (const url of data.images) {
        console.log('Removing image, ', url)
        context.dispatch('removeFile', { url })
      }

      await updateDoc(ref, {
        [data.time]: deleteField()
      })

      context.dispatch('fetchNews')
    },
    async publishEvent(context, { textSwe, textEng, date, editIndex }) {
      const data = {
        'textSwe': textSwe,
        'textEng': textEng,
        'date': date,
      }

      const ref = doc(db, "public", "calendar")
      

      if (editIndex === -1) context.state.events.push(data)
      else context.state.events[editIndex] = data

      await updateDoc(ref, {
        'events': context.state.events
      })

      context.dispatch('fetchCalendarEvents')
    },
    async deleteEvent(context, { editIndex }) {
      const ref = doc(db, "public", "calendar")
      
      console.log('Removing event', editIndex)
      context.state.events.splice(editIndex, 1)
      
      await updateDoc(ref, {
        'events': context.state.events
      })
      
      context.dispatch('fetchCalendarEvents')
    },
    async publishVideoCollection(context, { data }) {
      const ref = doc(db, "public", "videos")
      
      var replaced = false
      for (const v of context.state.videoCollections) {
        if (v.slug === data.slug) {
          context.state.videoCollections[context.state.videoCollections.indexOf(v)] = data
          replaced = true
        }
      }
      if (!replaced) context.state.videoCollections.push(data)
      
      console.log("Replaced: ", replaced)
      // console.log("New list: ", context.state.videoCollections)
      
      await updateDoc(ref, {
        'collections': context.state.videoCollections
      })
      
      context.dispatch('fetchVideoCollections')
    },
    async deleteCollection(context, { collection }) {
      const ref = doc(db, "public", "videos")

      await context.dispatch('removeFile', { 'url': collection['image'] })

      context.state.videoCollections.splice(
        context.state.videoCollections.indexOf(collection),
        1
      )

      await updateDoc(ref, {
        'collections': context.state.videoCollections
      })

      console.log('Successfully removed collection!')
    },
    async fetchNews(context) {
      const ref = doc(db, "public", "news")
      const snapshot = await getDoc(ref)
      const news = {}

      if (snapshot.exists()) {
        const entries = snapshot.data()
        for (const entry in entries) {
          news[entry] = entries[entry]
        }
        context.commit('setNews', news)
      } else {
        console.log('Could not read document: news')
      }
    },
    async fetchCalendarEvents(context) {
      const ref = doc(db, "public", "calendar")
      const snapshot = await getDoc(ref)
      const calendarEvents = []

      if (snapshot.exists()) {
        context.commit('setEvents', snapshot.data()['events'])
      }
    },
    async fetchVideoCollections(context) {
      const ref = doc(db, "public", "videos")
      const snapshot = await getDoc(ref)
      const collections = []

      if (snapshot.exists()) {
        context.commit('setVideoCollections', snapshot.data()['collections'])
      }
    },
    async sendEmail(context, { email, name, message }) {
      console.log('Attempting to send email.... ')
      
      if (await context.dispatch('runCAPTCHA')) {
        // analytics.logEvent('send_email', { email, name, message })

        // console.log('Sending mail, ', email, name, message)
        const addMessage = httpsCallable(functions, 'sendEmail')
        addMessage({ fromEmail: email, fromName: name, message })
          .then((result) => {
            console.log('Mail has been sent!', result)
          })
      }
    },
    async subscribeNewsletter(context, { email, firstName, lastName, swedish }) {
      console.log('Attempting to subscribe.... ')
      
      if (await context.dispatch('runCAPTCHA')) {
        // analytics.logEvent('subscribe_newsletter', { email, firstName, lastName, swedish })

        const sub = httpsCallable(functions, 'subscribe')
        sub({ email, firstName, lastName, swedish: true })
          .then((res) => {
            console.log('Subscribed successfully', res)
          })
      }
    },
    async runCAPTCHA() {
      const recaptcha = await load(
        '6LcpYtIeAAAAAHiBb3FvZaEzCiQoVrDa3ao1jAb9',
        {
          autoHideBadge: true,
        }
      )
      const token = await recaptcha.execute('submit')
      const verify = httpsCallable(functions, 'verifyChallange')
      const response = await verify({ token })
      console.log('reCAPTCHA', response.data.success)
      return response.data.success
    },
    async sendEvent() {
      console.log('Sending event...')
      logEvent(analytics, 'send_event', { event: 'test' })
      console.log('Event sent')
    }
  },
  modules: {
  }
})

const unsub = onAuthStateChanged(auth, (user) => {
  //TODO Check time since last login!
  try {
    const lastLogin = moment(new Date(+user.reloadUserInfo.lastLoginAt))
    const duration = moment.duration(moment().diff(lastLogin))
    if (duration.asHours() > 1) user = null
  } catch (e) {
    console.log('Error checking login time: ', e)
  }

  store.commit('setIsAuthReady', true)
  store.commit('setUser', user)
  store.dispatch('fetchNews')
  store.dispatch('fetchCalendarEvents')
  store.dispatch('fetchVideoCollections')
  unsub()
})

export default store