<template>
  <header class="header">
    <div class="content-box">
      <img class="hero" src="@/assets/Landing-page-header-2-edited.jpg">
      <h1 class="text--white text-light">{{ $t('home.cite') }}</h1>
    </div>
    <article class="home-welcome">
      <h1 class="text--white no-margin" style="padding-bottom: 15px">{{ $t('home.welcome') }}</h1>
      <p class="text--white">{{ $t('home.welcome_1') }}</p>
      <p class="text--white">{{ $t('home.welcome_2') }}</p>
      <p class="text--white">{{ $t('home.welcome_3') }}</p>
    </article>
  </header>
</template>

<script>
export default {
  methods: {
    sendEvent() {
      this.$store.dispatch('sendEvent');
    }
  }
}
</script>

<style>
.header {
  display: flex;
}
.hero {
  width: 50vw;
  height: 550px;
  object-fit: cover;
  box-sizing: border-box;
}
img {
  vertical-align: top;
}
.content-box {
  position: relative;
  text-align: center;
}
.content-box h1 {
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 90%;
}
.home-welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  background-color: #E5B409;
  text-align: start;
  padding-left: 5%;
  padding-right: 10%;
}

@media screen and (max-width: 1350px) {
  .home-welcome {
    padding-right: 5%;
  }
}

@media screen and (max-width: 1030px) {
  .header {
    flex-direction: column;
  }
  .home-welcome {
    padding: 5%;
  }
  .hero {
    width: 100vw;
    height: 50vh;
    object-fit: cover;
  }
  .content-box {
    overflow: hidden;
  }
}
</style>