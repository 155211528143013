<template>
  <footer>
    <img class="waves" src="@/assets/footer_waves.png" unselectable="on"/>
    <div class="footer-backdrop">
      <div class="full-width-padding">
        <form action="" class="footer-form" @submit.prevent="">
          <h3 class="text--white text-light" style="margin: 10px 0">{{$t('navbar.subscribe')}}:</h3>
          <input type="email" :placeholder="$t('footer.email')" v-model="$store.state.newsletterFooterEmail">
          <button @click="$store.commit('showModal', 1)" class="button-invert">{{$t('footer.subscribe')}}</button>
        </form>

        <div class="divider"></div>
        <ul class="links">
          <div class="left-col">
            <li><router-link to="/#">{{ $t('navbar.home') }}</router-link></li>
            <li><router-link to="/about">{{ $t('navbar.about') }}</router-link></li>
            <li><router-link to="/videos">{{ $t('navbar.videos') }}</router-link></li>
            <li><router-link to="/news">{{ $t('navbar.news') }}</router-link></li>
            <li><router-link to="/calendar">{{ $t('navbar.calendar') }}</router-link></li>
          </div>
          <div class="right-col">
            <li><router-link to="/partner">{{ $t('navbar.partner') }}</router-link></li>
            <li @click="$store.commit('showModal', 2)">{{ $t('navbar.contact') }}</li>
            <!-- <li><router-link to="/gift">{{ $t('navbar.gift') }}</router-link></li> -->
            <li @click="$store.commit('showModal', 0)">{{ $t('navbar.gift') }}</li>
            <li @click="toggleLocale" class="locale-toggle">{{ $t('navbar.locale') }}</li>
          </div>
        </ul>
        <div style="margin-top: 20px">
          <a href="https://www.facebook.com/Focus-on-the-Harvest-205975432871684" class="text-hoverable" style="margin-right: 20px"><FacebookIcon /></a>
          <a href="https://www.instagram.com/focusontheharvest/" class="text-hoverable"><InstagramIcon /></a>
        </div>
        <div class="footer-logo">
          <Logo />
        </div>
        <p class="text-xs text--white text-light">
          © Focus on the Harvest {{new Date().getFullYear()}} | <a href="https://www.devyne.se" class="text-hoverable">Devyne Software</a> | <router-link to="/privacy" class="text-hoverable text--white">Privacy Policy</router-link> | <router-link to="/admin" class="text-hoverable text--white">Admin</router-link>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import Logo from '../assets/logo_white.svg'
import FacebookIcon from '../assets/facebook.svg'
import InstagramIcon from '../assets/instagram.svg'

export default {
  components: {
    Logo,
    FacebookIcon,
    InstagramIcon
  },
  methods: {
    toggleLocale() {
      if(this.$i18n.locale == 'sv') this.$i18n.locale = 'en'
      else this.$i18n.locale = 'sv'
    },
  }
}
</script>

<style>
footer {
  position: relative;
  margin-top: auto;
}

.waves {
  z-index: 0;
  width: 100%;
  display: block;
  position: relative;
  bottom: -2px;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}

.footer-backdrop {
  position: relative;
  z-index: 0;
  background-color: #E5B409;
  display: flex;
  flex-direction: column;
}

.links {
  position: relative;
  display: flex;
  justify-content: center;
}

ul {
  padding: 0;
  margin: 0;
}

.left-col, .right-col {
  display: inherit;
}

.locale-toggle {
  position: absolute;
  top: -20px;
  right: 0;
  transform: translateY(-100%);
}

.links li {
  list-style-type: none;
  margin: auto 20px;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  transition: opacity 200ms ease;
  -o-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
}

.links a, li {
  text-decoration: none;
  color: #ffffff;
}

.links li:hover {
  opacity: 0.6;
}

.footer-logo {
  margin: 20px 0;
}

form {
  display: flex;
  flex-direction: column;
  max-width: 360px;
  justify-content: center;
  align-items: center;
  margin: 20px auto;

}

.footer-form input {
  border: none;
  background-color: #ebc340;
  color: white;
  font-size: 16px;
  height: 40px;
  border-radius: 8px;
  padding: 0 20px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
}

.footer-form input::placeholder {
  color: rgba(255, 255, 255, 0.658);
}

.footer-form input:focus {
  border: none;
  outline: none;
  /* background-color: #9e7d06; */
  background-color: #EBBE26;
}

@media screen and (max-width: 1150px) {
  .links {
    align-items: flex-start;
    column-gap: 30px;
  }

  .left-col, .right-col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .links li {
    margin: 5px 0;
  }
}

@media screen and (max-width: 700px) {
  .locale-toggle {
    position: relative;
    transform: translateY(0);
    top: 0;
  }
}
</style>