<template>
  <div class="full-width-padding content-backdrop">
    <slot></slot>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.content-backdrop {
  background-color: white;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.075);
  padding-top: 40px !important;
  padding-bottom: 40px !important;
  /* width: 100%;
  padding: 40px 10%;
  box-sizing: border-box; */
}
</style>