<template>
  <div id="app">
    <div class="content" :class="{'content-no-margin': hideNavigation}">
      <router-view/>
    </div>
    <div class="spacer" v-if="!hideNavigation" ></div>
    <!-- <Modal /> -->
    <Footer v-if="!hideNavigation" />
    <navbar v-if="!hideNavigation" />
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue'
import Footer from './components/Footer.vue'
import Modal from './components/Modal.vue'

export default {
  name: 'App',
  data() {
    return {
      
    }
  },
  components: {
    Navbar,
    Footer,
    Modal,
  },
  computed: {
    hideNavigation() {
      return !this.$route.meta.showNav
      // return this.$route.matched.some(route => route.path.includes('/admin'))
    }
  },
  metaInfo: {
    title: 'Home',
    titleTemplate: '%s | Focus on the Harvest'
  }
}
</script>


<style>
@import url('https://fonts.googleapis.com/css2?family=Yantramanav:wght@300;400;900&display=swap');

html, body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  background-color: #EEEEEE;
}

#app {
  display: flex;
  min-height: 100%;
  flex-direction: column;
}

.spacer {
  flex: 1;
  -webkit-box-flex: 1;
  min-height: 50px;
}

.content {
  margin-top: 120px;
}

.content-no-margin {
  flex: 1;
  -webkit-box-flex: 1;
  margin-top: 0;
  height: 100%;
}

@media screen and (max-width: 1150px) {
  .content {
    margin-top: 90px;
  }

  .content-no-margin {
    margin-top: 0;
  }
}


</style>
