<template>
  <div>
    <modal-header :title="$t('contact-modal.modal-title')" />
    <div class="newsletter-content">
      <div class="newsletter-information">
        <h4 class="text--primary">{{ $t('contact-modal.left-header') }}</h4>
        <p>{{ $t('contact-modal.left-info') }}</p>
      </div>
      <div class="newsletter-form">
        <div v-if="!subscribed" style="display: flex; justify-content: end">
          <form style="align-items: flex-start; width: 100%"  @submit.prevent="handleSubmit">
            <label for="name">{{ $t('contact-modal.form-name') }}</label>
            <input type="text" name="name" autocomplete="name" v-model="name" required>
            <label for="name" style="margin-top: 20px">{{ $t('contact-modal.form-email') }}</label>
            <input type="text" name="email" autocomplete="email" v-model="email" required>
            <label for="email" style="margin-top: 20px">{{ $t('contact-modal.form-message') }}</label>
            <!-- <input type="email" name="email" autocomplete="email" v-model="email" required> -->
            <textarea style="max-width: 100%; min-width: 100%; min-height: 150px; max-height: 300px" v-model="message" name="message" cols="30" rows="10" required></textarea>
            <div style="width: 100%; display: flex; justify-content: center">
              <button>{{ $t('contact-modal.form-submit') }}</button>
            </div>
          </form>
        </div>
        <div v-else style="text-align: center">
          <h3 class="text--primary">Ditt meddelande har skickats!</h3>
          <ThankYouSvg style="margin: 20px 0"/>
        </div>
        <div style="text-align: center">
          <p class="text-xxs">To see how Focus on the Harvest handles user data, view our <router-link to="/privacy" class="text--primary text-hoverable">Privacy Policy</router-link>.</p>
          <p class="text-xxs">This form implements reCAPTCHA v3. View Google 
            <a class="text--primary text-hoverable" href="https://www.google.com/policies/privacy/">Privay Policy</a> and 
            <a class="text--primary text-hoverable" href="https://www.google.com/policies/terms/">Terms of Use</a>.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalHeader from './ModalHeader.vue'
import ThankYouSvg from '../../assets/thank_you.svg'

export default {
  components: {
    ModalHeader,
    ThankYouSvg
  },
  data() {
    return {
      subscribed: false,
      name: '',
      message: '',
      email: '',
    }
  },
  methods: {
    handleSubmit() {
      // console.log('Submit!', this.firstName, this.lastName, this.email)
      this.$store.dispatch('sendEmail', {
        email: this.email,
        name: this.name,
        message: this.message,
        swedish: false,
      })
      this.subscribed = true
    }
  },
}
</script>

<style>

</style>