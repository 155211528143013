<template>
  <div>
    <modal-header :title="$t('news-letter-modal.modal-title')" />
    <div class="newsletter-content">
      <div class="newsletter-information">
        <h4 class="text--primary">{{ $t('news-letter-modal.left-header') }}</h4>
        <p>{{ $t('news-letter-modal.left-info') }}</p>
      </div>
      <div class="newsletter-form">
        <div v-if="!subscribed" style="display: flex; justify-content: end">
          <form style="align-items: flex-start; width: 100%"  @submit.prevent="handleSubmit">
            <label for="name">{{$t('news-letter-modal.form-name')}}</label>
            <input type="text" name="name" autocomplete="given-name" v-model="firstName" required>
            <label for="name" style="margin-top: 20px">{{$t('news-letter-modal.form-last-name')}}</label>
            <input type="text" name="name" autocomplete="family-name" v-model="lastName">
            <label for="email" style="margin-top: 20px">{{$t('news-letter-modal.form-email')}}</label>
            <input type="email" name="email" autocomplete="email" v-model="$store.state.newsletterFooterEmail" required>
            <div style="width: 100%; display: flex; justify-content: center">
              <button>{{$t('news-letter-modal.form-submit')}}</button>
            </div>
          </form>
        </div>
        <div v-else style="text-align: center">
          <h3 class="text--primary">Thank you for subscribing!</h3>
          <ThankYouSvg style="margin: 20px 0"/>
        </div>
        <div style="text-align: center">
          <p class="text-xxs">To see how Focus on the Harvest handles user data, view our <router-link to="/privacy" class="text--primary text-hoverable">Privacy Policy</router-link>.</p>
          <p class="text-xxs">This form implements reCAPTCHA v3. View Google 
            <a class="text--primary text-hoverable" href="https://www.google.com/policies/privacy/">Privay Policy</a> and 
            <a class="text--primary text-hoverable" href="https://www.google.com/policies/terms/">Terms of Use</a>.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalHeader from './ModalHeader.vue'
import ThankYouSvg from '../../assets/thank_you.svg'
// import mailchimp from '@mailchimp/mailchimp_marketing'

export default {
  components: {
    ModalHeader,
    ThankYouSvg
  },
  data() {
    return {
      subscribed: false,
      firstName: '',
      lastName: '',
      email: '',
    }
  },
  methods: {
    handleSubmit() {
      console.log('Submit!', this.firstName, this.lastName, this.email)
      this.$store.dispatch('subscribeNewsletter', {
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        swedish: false,
      })
      this.subscribed = true
    }
  },
}
</script>

<style>
.newsletter-content {
  width: 100%;
  display: flex;
  max-height: 90vh;
  overflow-y: auto;
}

.newsletter-information {
  flex: 1;
  padding: 40px;
  text-align: left;
}

.newsletter-form {
  flex: 1;
  padding: 40px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (max-width: 900px) {
  .newsletter-content {
    flex-direction: column;
  }
}
</style>