<template>
  <div>
    <nav v-click-outside="dismiss">
      <div class="infobar full-width-padding">
        <ul class="buttons">
          <li class="info-button row-vertical-center"><Icon icon="phone"/><a href="tel:0731-407766">0731-40 77 66</a></li>
          <li class="info-button row-vertical-center"><Icon icon="mail"/><a href="mailto:info@focusontheharvest.net">info@focusontheharvest.net</a></li>
        </ul>
        <ul class="buttons">
          <li class="info-button" @click="$store.commit('showModal', 0)">{{ $t('navbar.gift') }}</li>
          <li class="info-button" @click="$store.commit('showModal', 1)">{{ $t('navbar.subscribe') }}</li>
          <li class="info-button" @click="$store.commit('showModal', 2)">{{ $t('navbar.contact') }}</li>
          <li class="info-button" @click="toggleLocale">{{ $t('navbar.locale') }}</li>
        </ul>
      </div>
      <div class="nav full-width-padding" :class="{'nav-fixed': scrollPosision > 50}">
        <img :src="logo" class="logo" :class="{'nav-fixed': scrollPosision > 50}" @click="rout"/>
        <ul class="buttons">
          <li><router-link to="/#">{{ $t('navbar.home') }}</router-link></li>
          <li><router-link to="/about">{{ $t('navbar.about') }}</router-link></li>
          <li><router-link to="/videos">{{ $t('navbar.videos') }}</router-link></li>
          <li><router-link to="/news">{{ $t('navbar.news') }}</router-link></li>
          <li><router-link to="/calendar">{{ $t('navbar.calendar') }}</router-link></li>
          <li><router-link to="/partner">{{ $t('navbar.partner') }}</router-link></li>
        </ul>
        <div class="burger" @click="toggleBurger(!burgerActive)">
          <div class="line1" :class="{'active': burgerActive}"></div>
          <div class="line2" :class="{'active': burgerActive}"></div>
          <div class="line3" :class="{'active': burgerActive}"></div>
        </div>
      </div>
      <div class="burger-menu" :class="{'active': burgerActive}">
        <ul class="burger-buttons">
          <li @click="dismiss"><router-link to="/#">{{ $t('navbar.home') }}</router-link></li>
          <li @click="dismiss"><router-link to="/about">{{ $t('navbar.about') }}</router-link></li>
          <li @click="dismiss"><router-link to="/videos">{{ $t('navbar.videos') }}</router-link></li>
          <li @click="dismiss"><router-link to="/news">{{ $t('navbar.news') }}</router-link></li>
          <li @click="dismiss"><router-link to="/calendar">{{ $t('navbar.calendar') }}</router-link></li>
          <li @click="dismiss"><router-link to="/partner">{{ $t('navbar.partner') }}</router-link></li>
          <li class="info-button" @click="$store.commit('showModal', 0), dismiss()">{{ $t('navbar.gift') }}</li>
          <li class="info-button" @click="$store.commit('showModal', 1), dismiss()">{{ $t('navbar.subscribe') }}</li>
          <li class="info-button" @click="$store.commit('showModal', 2), dismiss()">{{ $t('navbar.contact') }}</li>
          <!-- <li @click="dismiss"><router-link to="/gift">{{ $t('navbar.gift') }}</router-link></li>
          <li @click="dismiss"><router-link to="/subscribe">{{ $t('navbar.subscribe') }}</router-link></li>
          <li @click="dismiss"><router-link to="/contact">{{ $t('navbar.contact') }}</router-link></li> -->
          <li @click="toggleLocale">{{ $t('navbar.locale') }}</li>
        </ul>
      </div>
    </nav>
    <modal />
  </div>
</template>

<script>
import logo from '@/assets/FOTH_logotyp_Roboto.png'
import Icon from '../components/Icon.vue'
import Modal from '../components/Modal.vue'

export default {
  data: () => {
    return {
      scrollPosision: null,
      logo,
      burgerActive: false,
    }
  },
  methods: {
    updateScroll() {
      this.scrollPosision = window.scrollY
    },
    rout() {
      this.$router.push("/#")
    },
    toggleBurger(state) {
      this.burgerActive = state
    },
    signOut() {
      this.$store.dispatch('logout')
    },
    toggleLocale() {
      if(this.$i18n.locale == 'sv') this.$i18n.locale = 'en'
      else this.$i18n.locale = 'sv'


      this.dismiss()
    },
    dismiss() {
      if(this.burgerActive) this.toggleBurger(false)
    },
  },
  computed: {
    email() {
      return this.$store.state.authIsReady ? (this.$store.state.user?.email) : '..'
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll)
  },
  unmounted() {
    window.removeEventListener('scroll', this.updateScroll)
  },
  components: {
    Icon,
    Modal,
  }
}
</script>

<style>

nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.nav {
  z-index: 999;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  background-color: white;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.075);
  transition: height 400ms ease;
}

.nav.nav-fixed {
  height: 70px;
}

.infobar {
  z-index: 999;
  background-color: #E5B409;
  /* padding: 0 10%; */
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;
  /* box-sizing: border-box; */
}

.burger-menu, .burger {
  display: none;
}

.logo {
  width: 190px;
  left: 0;
  margin: 10px 0;
  box-sizing: border-box;
  transition: width 400ms ease;
  cursor: pointer;
}

.logo.nav-fixed {
  width: 110px;
}

.buttons {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  box-sizing: inherit;
}

.buttons li.info-button a {
  color: white;
}

.buttons li.info-button {
  list-style-type: none;
  margin: auto 10px;
  font-size: 16px;
  text-transform: uppercase;
  color: white;
  cursor: pointer;
  letter-spacing: 1px;
  display: flex;
}

.buttons a {
  text-decoration: none;
  color: #E5B409;
}

.buttons li {
  list-style-type: none;
  margin: auto 20px;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  transition: opacity 200ms ease;
  -o-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
}

.buttons li:last-child {
  margin: auto 0 auto 20px;
}

.buttons li.info-button:first-child {
  margin: auto 10px auto 0;
}

.buttons li.info-button:last-child {
  margin: auto 0 auto 10px;
}

.buttons li:hover {
  /* text-decoration: underline;
  text-underline-offset: 5px; */
  opacity: 0.6;
}

@media screen and (max-width: 1350px) {
  /* .nav {
    padding: 0 5%;
  } */
  /* .infobar {
    padding: 0 5%;
  } */

  .buttons li.info-button {
    margin: auto 7px;
    letter-spacing: 1px;
  }
  .buttons li.info-button:first-child {
    margin: auto 7px auto 0;
  }

  .buttons li.info-button:last-child {
    margin: auto 0 auto 7px;
  }

  .buttons li {
    margin: auto 7px;
  }
  .buttons li:last-child {
    margin: auto 0 auto 7px;
  }
}

@media screen and (max-width: 1150px) {
  .infobar {
    display: none;
  }
  .buttons {
    display: none;
  }
  .nav {
    position: relative;
    
  }
  .burger-menu {
    position: relative;
    display: flex;
    z-index: 998;
    background-color: white;
    justify-content: center;
    /* transform: translateY(-100%); */
    margin-top: -400px;
    -webkit-transition: margin-top 400ms ease;
    transition: margin-top 400ms ease;
  }

  .burger-menu.active {
    /* transform: translateY(0%); */
    margin-top: 0;
  }

  .burger-menu ul {
    margin: 0;
    padding: 0;
  }
  .burger-menu li {
    list-style-type: none;
    font-size: 20px;
    letter-spacing: 2px;
    cursor: pointer;
    color: #E5B409; 
    margin: 10px 0;
    text-transform: uppercase;
    transition: opacity 200ms ease;
    -o-transition: opacity 400ms ease;
    -moz-transition: opacity 400ms ease;
  }
  .burger-menu li a {
    color: #E5B409; 
    text-decoration: none;
  }
  .burger-menu li:hover {
    opacity: 0.6;
  }

  .burger {
    cursor: pointer;
    display: inline-block;
    z-index: 998;
  }
  .line1, .line2, .line3 {
    background-color: #E5B409;
    width: 30px;
    height: 5px;
    margin: 5px 0;
    border-radius: 10px;
    -webkit-transition: all 400ms ease;
    transition: all 400ms ease;
  }
  .line1.active {
    background-color: #E5B409;
    transform: translate(0, 10px) rotate(135deg);
  }
  .line2.active {
    background-color: #E5B409;
    /* transform: translateX(-200%); */
    opacity: 0;
  }
  .line3.active {
    background-color: #E5B409;
    transform: translate(0, -10px) rotate(-135deg);
  }
}

</style>