<template>
  <div class="home">
    <home-header />
    <content-sheet>
      <h1 class="text--primary text-left">{{ $t('home.news') }}</h1>
      <div class="home-news-grid">
        <div v-for="entry in newsList" :key="entry.time">
          <home-news-entry :data="entry" />
        </div>
        <h4 style="margin: auto" class="text-hoverable text--primary"><router-link to="news">{{ $t('news.more-news') }}</router-link></h4>
      </div>
    </content-sheet>
    <div class="quote-container">
      <p>{{ $t('home.verse') }}</p>
      <p class="source">
        {{ $t('home.verse-source') }}
      </p>
    </div>
    <!-- <button @click="sendMail">Send mail</button>
    <button @click="sub">Subscribe</button>
    <button @click="captcha">reCAPTCHA</button> -->
  </div>
</template>

<script>
import HomeHeader from '../components/home/HomeHeader.vue'
import ContentSheet from '../components/ContentSheet.vue'
import HomeNewsEntry from '../components/news/HomeNewsEntry.vue'

export default {
  name: 'Home',
  metaInfo() {
    return {
      title: this.$t('navbar.home')
    }
  },
  data: () => {
    return {
    }
  },
  methods: {
    sendMail() {
      this.$store.dispatch('sendEmail')
    },
    sub() {
      this.$store.dispatch('subscribeNewsletter')
    },
    captcha() {
      this.$store.dispatch('runCAPTCHA')
    }
  },
  computed: {
    newsList() {
    
      var keys = Object.keys(this.$store.state.news)

      keys = keys.filter((key) => {
        if(this.$i18n.locale !== 'sv') {
          const article = this.$store.state.news[key]
          return article.title[1] && article.content[1]
        }
        return true
      })

      keys.sort((a, b) => {
        if (a < b) return 1 
        if (a > b) return -1
        return 0
      })

      const news = {}
      for(const key of keys.splice(0, 3)) news[key] = this.$store.state.news[key]

      return news
    }
  },
  components: {
    HomeHeader,
    ContentSheet,
    HomeNewsEntry
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>

<style>
.cal {
  width: 70%;
  margin: 0 auto;
}

.home-news-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 30px;
  column-gap: 30px;
  justify-content: space-between;
}

.quote-container {
  max-width: 600px;
  width: 90%;
  margin: 130px auto 80px;
}
.quote-container p {
  text-align: left;
  color: rgb(87, 87, 87);
  font-style: italic;
}
.quote-container p.source {
  color: rgb(46, 46, 46);
  font-style: normal;
  font-weight: bold;
  text-align: right;
}

@media screen and (max-width: 1030px) {
  .home-news-grid {
    display: flex;
    flex-direction: column;
  }
  
}
</style>
