<template>
  <vue-final-modal v-model="$store.state.showModal" :lock-scroll="true" classes="modal-container" content-class="modal-content">
    <gift-modal v-if="$store.state.activeModal === 0" />
    <newsletter-modal v-else-if="$store.state.activeModal === 1" />
    <contact-modal v-else-if="$store.state.activeModal === 2" />
    <div v-else>
      <h2>Error 404</h2>
    </div>
  </vue-final-modal>
</template>

<script>
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'
import ContentSheet from './ContentSheet.vue'
import GiftModal from './modals/GiftModal.vue'
import NewsletterModal from './modals/NewsletterModal.vue'
import ContactModal from './modals/ContactModal.vue'

export default {
  components: {
    VueFinalModal, 
    ModalsContainer,
    ContentSheet,
    GiftModal,
    NewsletterModal,
    ContactModal
  },
  methods: {
    tmpScollLock(e) {
      console.log('Scrolling')
    }
  },
  updated() {
    if(this.$store.state.showModal) window.addEventListener('wheel', this.tmpScollLock)
    else window.removeEventListener('wheel', this.tmpScollLock)
  },
}
</script>

<style>

.body-fixed {
  position: fixed;
  overflow-y: scroll;
}

.modal-container {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  /* height: 100%; */
  position: fixed;
  overflow: scroll;
}

.modal-content {
  width: 95%; 
  max-width: 1200px;
  background-color: white; 
  margin: auto 0;
}
</style>