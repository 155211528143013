<template>
  <div class="modal-header-backdrop">
    <h1 class="text--white" style="margin: 0 0">{{ title }}</h1>
    <div class="modal-header-exit text-hoverable" @click="$store.state.showModal = false">
      <div class="modal-header-exit-line1"></div>
      <div class="modal-header-exit-line2"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: '',
  }
}
</script>

<style>
.modal-header-backdrop {
  display: flex;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  background-color: #E5B409;
  padding: 15px;
  width: 100%;
}

.modal-header-exit {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.modal-header-exit > div {
  width: 30px;
  height: 5px;
  margin: 5px 0;
  border-radius: 5px;
  background-color: white;
}

.modal-header-exit-line1 {
  transform: translateY(5px) rotate(45deg);
}

.modal-header-exit-line2 {
  transform: translateY(-5px) rotate(-45deg);
}

@media screen and (max-width: 700px) {
  .modal-header-backdrop > h1 {
    font-size: 20px;
  }
  
  .modal-header-backdrop {
    justify-content: flex-start;
  }

  .modal-header-exit {
    right: 15px
  }
}
</style>