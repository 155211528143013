import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      showNav: true,
      title: 'Focus on the Harvest'
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      showNav: true,
      title: 'About',
    }
  },
  {
    path: '/videos',
    component: () => import('../views/VideoLibrary.vue'),
    meta: {
      showNav: true,
      title: 'Videos'
    }
  },
  {
    path: '/videos/:slug',
    component: () => import('../views/VideoCollection.vue'),
    meta: {
      showNav: true,
      title: 'Videos'
    }
  },
  {
    path: '/news',
    component: () => import('../views/News.vue'),
    meta: {
      showNav: true,
      title: 'News'
    }
  },
  {
    path: '/news/:slug',
    component: () => import('../views/NewsArticle.vue'),
    meta: {
      showNav: true,
      title: 'News'
    }
  },
  {
    path: '/calendar',
    component: () => import('../views/Calendar.vue'),
    meta: {
      showNav: true,
      title: 'Calendar'
    }
  },
  {
    path: '/partner',
    component: () => import('../views/Partner.vue'),
    meta: {
      showNav: true,
      title: 'Partner with us'
    }
  },
  {
    path: '/modal',
    component: () => import('../views/MobileModal.vue'),
    meta: {
      showNav: true,
      title: 'Focus on the Harvest'
    }
  },
  {
    path: '/admin',
    component: () => import('../views/admin/Admin.vue'),
    meta: {
      showNav: false,
      title: 'Admin'
    },
    children: [
      {
        path: '/',
        component: () => import('../views/admin/AdminDashboard.vue')
      },
      {
        path: 'news',
        component: () => import('../views/admin/AdminNews.vue')
      },
      {
        path: 'calendar',
        component: () => import('../views/admin/AdminCalendar.vue')
      },
      {
        path: 'videos',
        component: () => import('../views/admin/AdminVideos.vue')
      },
      {
        name: 'Video-Write',
        path: 'videos-write',
        props: true,
        component: () => import('../views/admin/AdminVideoCollection.vue')
      },
      {
        name: 'News-Write',
        path: 'news/write',
        props: true,
        component: () => import('../views/admin/AdminNewsWrite.vue')
      },
    ]
  },
  {
    path: '/privacy',
    component: () => import('../views/PrivacyPolicy.vue')
  },
  {
    path: '*',
    component: () => import('../views/PageNotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
